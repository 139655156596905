import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Features from "../components/home/features"
import Section from "../components/section"
import Links from "../components/links"
import SEO from "../components/seo"

const money = (
  <svg
    className="w-6 h-6"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
)

const calendar = (
  <svg
    className="w-6 h-6"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
    />
  </svg>
)

const chat = (
  <svg
    className="w-6 h-6"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
    />
  </svg>
)

const hero = {
  headline: "Bet - Biri",
  headlineAccent: "laat dingen verdwijnen...",
  text:
    "Bet - Biri laat dingen verdwijnen waar het licht gaat op schijnen. Voor meer informatie, bezoek de over ons pagina of contacteer ons en wij helpen je zo snel mogelijk verder!",
  cta: {
    primary: { label: "Maak een afspraak", to: "/appointment" },
  },
}

const steps = {
  title: "Ons stappenplan",
  subtitle: "Verloop van de behandeling",
  features: [
    {
      icon: chat,
      title: "Inleiding gesprek of intake gesprek",
      text:
        "Dit gesprek is nodig om de klant zijn <u>noden</u> te weten. Als volgt leg ik uit aan de klant wat en hoe we zijn noden kunnen verhelpen of inwilligen, wat de klant in kwestie kan doen om het proces te vereenvoudigen, wat ten slotte ook de prijs doet dalen (hoe minder werk de behandelend persoon heeft hoe goedkoper de behandeling wordt)",
    },
    {
      icon: money,
      title: "Inschattingen en testen",
      text:
        "Er wordt tijdens dit gesprek een inschatting gemaakt van de behandelingstijd waaraan ook een kostprijs kan gegeven worden <br /><b>(€ 25 per begonnen kwartier)</b>. <br /><br />Tijdens dit gesprek wordt tevens ook een test gedaan op: lichtgevoeligheid, U.V. allergie, resistentie, en dit wordt steeds op een plaats gedaan dat niet direct zichtbaar is: armen, benen,... (Maar zeker nooit in het gelaat). <br /><br /><b>Deze inleiding is van toepassing voor elke behandeling met de V.P.L.</b>",
    },
    {
      icon: calendar,
      title: "Opstart behandeling (2de afspraak)",
      text:
        "We <u>analyseren</u> de <i>te behandelende zone</i> en indien nodig bijscheren, inkleuren en aftekenen. De gel wordt aangebracht om een beter contact te genereren tussen de huid, haar en het toestel",
    },
  ],
}

const extraInfo = [
  {
    title: "Onze verschillende behandelingen met VPL",
    to: "/info/",
  },
  {
    title: "Wat gebeurt nu tijdens een haarvermindering?",
    to: "/info/vpl-light-treatment",
  },
  {
    title: "Welke informatie hebt u nodig bij het maken van een afspraak?",
    to: "/appointment",
  },
]

const IndexPage = ({ data, ...props }) => {
  const heroImage = data?.hero?.childImageSharp?.fluid
  const heroData = { ...hero, image: heroImage }

  return (
    <Layout hero={heroData} {...props}>
      <SEO title="Home" />
      <Features {...steps} />
      <Section
        // background="bg-gradient-to-r from-white to-gray-100"
        title="Extra"
        subtitle="Meer informatie"
      >
        <div className="mt-4">
          <Links data={extraInfo} center />
        </div>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query IndexPageQuery {
    hero: file(relativePath: { eq: "hero.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
