import React from "react"
import PropTypes from "prop-types"
import Section from "../section"

const Features = ({ title, subtitle, text, features }) => (
  <Section title={title} subtitle={subtitle} text={text}>
    {features?.length > 0 && (
      <div className="mt-10">
        <ul className="lg:grid lg:grid-cols-3 lg:gap-x-8 lg:gap-y-10">
          {features.map(({ title, text, icon }, i) => {
            const first = i === 0
            const listClass = !first ? "mt-10 md:mt-0" : ""

            return (
              <li key={title} className={listClass}>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 text-white rounded-md bg-primary-500">
                      {icon}
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg font-medium leading-6 text-gray-900">
                      {title}
                    </h4>
                    <div
                      className="mt-2 text-base leading-6 text-gray-500"
                      dangerouslySetInnerHTML={{ __html: text }}
                    />
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    )}
  </Section>
)

Features.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  features: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string, text: PropTypes.string })
  ),
}

export default Features
